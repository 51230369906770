import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import privacyPolicy from '../assets/images/privacy-policy.jpg'

const PrivacyPolicy = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Privacy Policy" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Privacy Policy" 
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                                <img src={privacyPolicy} alt="pp" />
                                <p><i>This Privacy Policy was last updated on July 1st, 2022.</i></p>
                                <h3>Privacy Policy</h3>
                                <p>This policy is intended to inform you about the information we collect from your visits to our website, how we use that information, and with whom the information may be shared.</p>
                                <h3>Scope</h3>
                                <p>This Privacy Policy applies to information that we collect online through our website. It does not apply to information that we collect by other means, such as information you share with us verbally, in writing, in email messages, or to information obtained during the course of creating a contract for work. This Privacy Policy also does not cover any information collected by any third-party site or third-party application linked from our website.</p>
                                <h3>Information We Collect from Website Visits</h3>
                                <p>Information we obtain from your visits to our website can be broadly described as the personal information that visitors provide us and technical information that we collect through the use of technology concerning those visits.</p>
                                <h3>Personal Information</h3>
                                <p>Visitors to our website have the opportunity to contact us for a follow-up communication. From our website, we will collect any information that you voluntarily provide us such as your name, email address, telephone number, and other business contact information. </p>
                                <h3>Third-party sites</h3>
                                <p>Our website uses Google Analytics to assess the traffic to the site.  The cookies used by Google Analytics can be <a href="https://policies.google.com/technologies/cookies?hl=en-US">viewed here</a>.  Google Analytics collects the following information:</p>

                                <blockquote className="blockquote">
                                    <ul>
                                        <li>Number of users</li>
                                        <li>Session statistics</li>
                                        <li>Approximate geolocation</li>
                                        <li>Browser and device information</li>
                                    </ul>
                                </blockquote>

                                <p>More information about what Google Analytics collects can be <a href="https://support.google.com/analytics/answer/11593727?hl=en#:~:text=Google%20Analytics%20collects%20the%20following,Approximate%20geolocation">found here</a>.</p>

                                <h3>Use of information collected</h3>
                                <p>No information collected from this site is sold to any third-party site and is only used to facilitate the operation of Smarter Spreadsheets LLC in partnering with clients.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="#">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms-of-service">
                                                Terms of Service
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;